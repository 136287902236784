<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <!-- <div class="c-avatar">
          <img src="img/avatars/6.jpg" class="c-avatar-img" />
        </div> -->
        {{ userName }}
        <CIcon name="cil-chevron-bottom" class="chevron-btm" />

      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="onProfile">
      <CIcon name="cil-user" /> {{ userName }}
    </CDropdownItem>
    <CDropdownItem @click="onLogout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
      userName: null
    };
  },
  methods: {
    onProfile() {
      this.$router.push({ path: "/profile" });
    },
    onLogout() {
      let self = this;
      auth.logout();
      self.$router.push({ path: "/pages/login" });
    },
  },
  mounted() {
    let userData = JSON.parse(window.localStorage.getItem('user'));
    this.userName = userData.name
  }
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}

.chevron-btm {
  margin-left: 10px;
}
</style>
